<template>
  <mdb-footer class="footer">
    <div class="footer-copyright">
      <div class="container-fluid align-items-center d-flex justify-content-between">
        <p class="mb-0 py-3">
          &copy; {{ new Date().getFullYear() }} Copyright:
          <a href="https://app.tecbill.net"> TECBILL APP</a>
        </p>
        <div class="d-flex justify-content-end align-items-center">
          <span class="font-weight-bold"
            ><i
              class="fas"
              :class="$store.state.theme === 'theme-light' ? 'fa-sun' : 'fa-moon'"
            ></i
          ></span>
          <mdb-switch
            @click.native="switchTheme"
            v-model="computeMode"
            offLabel=""
            onLabel=""
          ></mdb-switch>
        </div>
      </div>
    </div>
  </mdb-footer>
</template>

<script>
import { mdbFooter, mdbSwitch } from "mdbvue";
import themeSwitcher from "../../utils/darklight";

export default {
  name: "Footer",
  components: {
    mdbFooter,
    mdbSwitch,
  },
  data() {
    return {
      mode: this.$store.state.theme === "theme-light" ? false : true,
    };
  },
  methods: {
    async switchTheme(e) {
      e.preventDefault();
      if (this.$store.state.theme === "theme-light") {
        await this.$store.commit("toggleFooterModeDark", true);
        themeSwitcher("theme-dark");
      } else {
        await this.$store.commit("toggleFooterModeDark", false);
        themeSwitcher("theme-light");
      }
    },
  },
  computed: {
    computeMode() {
      return this.$store.state.modeFooter;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
