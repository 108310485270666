<template>
  <div class="sidenav ts-sidenav-skins mdb-admin-sidenav">
    <mdb-side-nav
      :mask="$store.state.theme === 'theme-light' ? 'strong' : ''"
      :logo="`${$store.state.url}img/petit.png`"
      logoClass="tail"
      :sideNavClass="
        $store.state.theme !== 'theme-light' ? 'sidenav-background-color' : 'sn-bg-1'
      "
      :OpenedFromOutside.sync="toggle"
    >
      <li>
        <mdb-side-nav-nav> 
          <mdb-side-nav-item
            header
            @click.native="width < 1440 && toggleSideNav(false)"
            icon="tachometer-alt"
            to="/"
            v-if="$gate.checkTypeRole(['super-admin', 'admin', 'user'])"
          >
            Tableau de bord
          </mdb-side-nav-item>

          <mdb-side-nav-cat
            v-if="$gate.hasPermission([
              'view-any::user',
              'view-any::site',
              'view-any::role',
            ])"
            name="Gestions des utilisateurs"
            icon="users"
          >
            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="cubes"
              to="/sites"
              v-if="$gate.hasPermission([ 
                'view-any::site',
            ])"
            >
              Sites
            </mdb-side-nav-item>
            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="shield-alt"
              to="/roles"
              v-if="$gate.hasPermission([
              'view-any::role',
            ])"
            >
              Rôles
            </mdb-side-nav-item>
            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="users"
              to="/utilisateurs"
              v-if="$gate.hasPermission([
              'view-any::user',
            ])"
            >
              Utilisateurs
            </mdb-side-nav-item>
          </mdb-side-nav-cat>

          <mdb-side-nav-item
            header
            @click.native="width < 1440 && toggleSideNav(false)"
            icon="file-invoice"
            to="/factures"
            v-if="$gate.hasPermission([
              'view-any::invoice',
            ])"
          >
            Factures
          </mdb-side-nav-item>
          <mdb-side-nav-item
            header
            @click.native="width < 1440 && toggleSideNav(false)"
            icon="dollar-sign"
            to="/ventes-credit"
            v-if="$gate.hasPermission([
              'view-any::credit-sale',
            ]) && $store.state.auth.company.setting.debt_actived === 1"
          >
            Ventes à crédit
          </mdb-side-nav-item>
          <mdb-side-nav-item
            header
            @click.native="width < 1440 && toggleSideNav(false)"
            icon="dollar-sign"
            to="/remboursements"
            v-if="$gate.hasPermission([
              'view-any::debt-repayment',
            ]) && $store.state.auth.company.setting.debt_actived === 1"
          >
            Remboursements de dettes
          </mdb-side-nav-item>
          <mdb-side-nav-item
            header
            @click.native="width < 1440 && toggleSideNav(false)"
            icon="cogs"
            to="/parametres"
            v-if="$gate.hasPermission(['view-any::setting'])"
          >
            Paramètres
          </mdb-side-nav-item>

          <mdb-side-nav-cat
            v-if="$gate.hasPermission([
              'view-any::article',
              'view-any::depot',
              'view-any::customer',
              'view-any::credit-plafond-customer',
              'view-any::price',
            ])"
            name="Paramètres Factures"
            icon="cogs"
          >
            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="cubes"
              to="/articles"
              v-if="$gate.hasPermission(['view-any::article'])"
            >
              Articles
            </mdb-side-nav-item>

            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="th-large"
              to="/depots"
              v-if="$gate.hasPermission(['view-any::depot'])"
            >
              Dépots
            </mdb-side-nav-item>
            
            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="address-book"
              to="/clients"
              v-if="$gate.hasPermission([
                'view-any::customer',
              ]) && !$gate.checkTypeRole(['user'])"
            >
              Clients
            </mdb-side-nav-item>

            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="address-book"
              to="/utilisateurs/clients"
              v-if="$gate.hasPermission([
                'view-any::customer',
              ]) && $gate.checkTypeRole(['user'])"
            >
              Clients
            </mdb-side-nav-item>


            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="dollar-sign"
              to="/credit-plafond-customers"
              v-if="($gate.hasPermission(['view-any::credit-plafond-customer']) && $store.state.auth.company.setting.debt_actived === 1)"
            >
              Plafonds de crédit
            </mdb-side-nav-item>
            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="dollar-sign"
              to="/structures-prix"
              v-if="$gate.hasPermission(['view-any::price'])"
            >
              Structures des prix
            </mdb-side-nav-item>
          </mdb-side-nav-cat>
          <mdb-side-nav-cat
            v-if="$gate.checkTypeRole(['super-admin'])"
            name="Gestion Marketing"
            icon="users"
          >
            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="users"
              to="/admin-tec/commerciaux"
              v-if="$gate.checkTypeRole(['super-admin'])"
            >
              Commerciaux
            </mdb-side-nav-item>
            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="pen-fancy"
              to="/admin-tec/inscriptions"
              v-if="$gate.checkTypeRole(['super-admin'])"
            >
              Inscriptions
            </mdb-side-nav-item>
          </mdb-side-nav-cat>
          <mdb-side-nav-cat
            v-if="$gate.checkTypeRole(['super-admin'])"
            name="Gestions des entreprises"
            icon="building"
          >
            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="building"
              to="/admin-tec/entreprises"
              v-if="$gate.checkTypeRole(['super-admin'])"
            >
              Entreprises
            </mdb-side-nav-item>
            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="credit-card"
              to="/admin-tec/abonnements"
              v-if="$gate.checkTypeRole(['super-admin'])"
            >
              Abonnements
            </mdb-side-nav-item>
            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="credit-card"
              to="/admin-tec/tarifs"
              v-if="$gate.checkTypeRole(['super-admin'])"
            >
              Tarifs
            </mdb-side-nav-item>
          </mdb-side-nav-cat>
          <mdb-side-nav-cat
            v-if="$gate.checkTypeRole(['super-admin'])"
            name="Gestions MCF"
            icon="hdd"
          >
            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="credit-card"
              to="/admin-tec/paiements-type"
              v-if="$gate.checkTypeRole(['super-admin'])"
            >
              Paiements & Type Facture
            </mdb-side-nav-item>
            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="th-large"
              to="/admin-tec/modele-factures"
              v-if="$gate.checkTypeRole(['super-admin'])"
            >
              Modeles Factures
            </mdb-side-nav-item>
            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="th-large"
              to="/admin-tec/categories"
              v-if="$gate.checkTypeRole(['super-admin'])"
            >
              Catégories Comptables
            </mdb-side-nav-item>
          </mdb-side-nav-cat>
          <mdb-side-nav-item
            header  
            @click.native="goToSalePoint()"
            icon="shopping-cart"
            target="_blank"
            :href="tbaconf.production_tip? tbaconf.tba_sp_prod :  tbaconf.tba_sp_dev"
            v-if="$gate.checkTypeRole(['super-admin', 'admin', 'user']) && $store.state.auth.company.setting.salepoint_activated"
          >
            Gestion des points de vente
          </mdb-side-nav-item>

          <mdb-side-nav-cat name="Rapports" icon="chart-area"
          v-if="$gate.hasPermission([
            'view::invoice-report',
            'view::activity-report',
            'view::quantity-sold-report',
            'view::invoice-report'])"
          >
            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="chart-bar"
              to="/reports/activities"
              v-if="$gate.hasPermission(['view::activity-report'])"
            >
              Rapport d'activité
            </mdb-side-nav-item>
            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="chart-bar"
              to="/reports/quantities"
              v-if="$gate.hasPermission(['view::quantity-sold-report'])"
            >
              Quantité vendue
              <!-- <span class="ml-2 badge rounded-pill bg-primary">Nouveau</span> -->
            </mdb-side-nav-item>
            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="chart-bar"
              to="/reports/sales_revenues"
            >
              Chiffre d'affaire
              <!-- <span class="ml-2 badge rounded-pill bg-primary">Nouveau</span> -->
            </mdb-side-nav-item>
            <mdb-side-nav-item
              header
              @click.native="width < 1440 && toggleSideNav(false)"
              icon="chart-bar"
              to="/reports/invoices"
              v-if="$gate.hasPermission(['view::invoice-report'])"
            >
              Raports factures 
            </mdb-side-nav-item>
          </mdb-side-nav-cat>
        </mdb-side-nav-nav>
      </li>
    </mdb-side-nav>
  </div>
</template>

<script>
import { mdbSideNav, mdbSideNavNav, mdbSideNavCat, mdbSideNavItem, waves } from "mdbvue";
import { mapGetters, mapActions } from "vuex";
import tstbaConf from '../../tstba.conf';
export default {
  name: "SideNav",
  props: {
    waves: {
      type: Boolean,
      default: true,
    },
    open: {
      type: Boolean,
    },
  },
  components: {
    mdbSideNav,
    mdbSideNavNav,
    mdbSideNavCat,
    mdbSideNavItem,
  },
  data() {
    const tbaconf = tstbaConf
    return {
      tbaconf,
      active: 0,
      elHeight: 0,
      windowHeight: 0,
      toggle: true,
      width: 0,
    };
  },
  computed: mapGetters(["sidenav"]),
  methods: {
    ...mapActions(["toggleSideNav"]),
    afterEnter(el) {
      el.style.maxHeight = "1000px";
    },
    beforeLeave(el) {
      el.style.maxHeight = 0;
    },
    setWidth() {
      this.width = window.innerWidth;
    },
    goToSalePoint(){
      this.$store.dispatch("auth/setTecbillAuth",localStorage.getItem('token'))
    }
  },
  mounted() {
    this.setWidth();
    window.addEventListener("resize", this.setWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setWidth);
  },
  watch: {
    toggle(value) {
      this.toggleSideNav(value);
    },
    sidenav(value) {
      this.toggle = value;
    },
    width(value) {
      if (value < 1440) {
        this.toggle = false;
      } else this.toggle = true;
    },
  },
  mixins: [waves],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
.side-nav {
  opacity: 1 !important;
  transition: transform 0.3s linear !important;
}
.tail {
  height: 100px !important;
}

/* Extra large devices (large desktops, 1200px and up) */
@media (max-width: 2200px) {
  .tail {
    height: 100% !important;
  }
}
</style>
