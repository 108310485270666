<template>
<div id="app" class="fixed-sn">

    <!--SideNav--> 
    <side-nav class="white-skin"/>
    <!--/SideNav-->

    <div class="flexible-content" >
      <!--Navbar-->
      <navbar :page="activePage" />
      <!--/Navbar-->

      <!--MainContent-->
      <main>
        <div>
          <router-view></router-view>
        </div>
      </main>
      <!--/MainContent-->

      <!--Footer-->
      <div class="white-skin">
        <copyrights />
      </div>
      <!--/Footer-->

    </div>

</div>
</template>

<script>
import SideNav from '@/tba/components/SideNav'
import Navbar from '@/tba/components/Navbar'
import Copyrights from '@/tba/components/Footer'

export default {
  name: 'App',
  components: {
    SideNav,
    Navbar,
    Copyrights
  },
  data () {
    return {
      activePage: 'Dashboard'
    }
  },
  mounted () {
    this.activePage = this.$route.name
  },
  updated () {
    this.activePage = this.$route.name
  }
}
</script>

<style lang="scss" >
  body {
    font-weight: 300;
    background-color: #eee!important;
  }
</style>