<template>
  <div class="header">
    <mdb-navbar
      class="flexible-navbar navbar-background-color navbar-color"
      position="top"
      double
      scrolling
    >
      <mdb-navbar-brand @click.native="toggleSideNav" class="button-collapse">
        <mdb-icon icon="bars" />
      </mdb-navbar-brand>
      <mdb-navbar-nav class="nav-active" left>
        <div class="activePage">{{ computedPage.activePage }}</div>
        <div class="pl-3">
          <span
            v-if="$store.state.auth.site.production_tip === 'production'"
            class="badge badge-success text-uppercase"
          >
            {{ $store.state.auth.site.production_tip }}</span
          >
          <span
            v-if="$store.state.auth.site.production_tip === 'test'"
            class="badge badge-warning text-uppercase"
          >
            {{ $store.state.auth.site.production_tip }}</span
          >
        </div>
        <!-- <div>
          <mdb-btn @click="handleQe" color="primary"></mdb-btn>
          <a href="http://localhost:5173" class="btn btn-primary">TBA PV Station</a> -->
        <!-- </div> -->
        <!-- <el-popover
        placement="bottom"
        :title="$store.state.auth.company.name"
        width="200"
        trigger="click"
        :content="$store.state.auth.site.name">
        <el-button slot="reference" >
          <i class="fas fa-info btn-rounded"></i>
        </el-button>
      </el-popover> -->
      </mdb-navbar-nav>

      <mdb-navbar-nav class="pr-4" right>
        <mdb-nav-item
          class="ml-2 px-2 item-effect"
          @click="doSearch()"
          style="cursor: pointer"
        >
          <mdb-icon fas icon="search" /><span
            class="clearfix d-none d-sm-inline-block"
          ></span>
        </mdb-nav-item>
        <mdb-dropdown class="nav-item item-effect" tag="li">
          <mdb-dropdown-toggle
            slot="toggle"
            tag="li"
            outline="none"
            class="btn-transparent item-effect"
          >
            <mdb-icon icon="user" />
            <span class="clearfix d-none d-sm-inline-block pl-2">Mon Profile</span>
          </mdb-dropdown-toggle>
          <mdb-dropdown-menu color="primary" left>
            <mdb-dropdown-item @click="$router.push('/mon-compte')"
              ><i class="pr-2 fas fa-user-circle"></i
              >{{ $store.state.auth.user.firstname }}
              <span class="text-uppercase">{{ $store.state.auth.user.lastname }}</span>
            </mdb-dropdown-item>
            <mdb-dropdown-item
              @click="switchTheme('theme-dark')"
              v-if="$store.state.theme === 'theme-light'"
            >
              <i class="pr-2 fas fa-moon"></i> Thème Foncé</mdb-dropdown-item
            >
            <mdb-dropdown-item @click="switchTheme('theme-light')" v-else
              ><i class="pr-2 fas fa-sun"></i> Thème Clair</mdb-dropdown-item
            >
            <mdb-dropdown-item @click="lock">
              <i class="pr-2 fas fa-lock"></i> Verrouiller
            </mdb-dropdown-item>
            <mdb-dropdown-item @click="logout">
              <i class="pr-2 fas fa-sign-out-alt"></i>Se déconnecter
            </mdb-dropdown-item>
          </mdb-dropdown-menu>
        </mdb-dropdown>
      </mdb-navbar-nav>
    </mdb-navbar>
    <!-- confirm modal -->
    <mdb-modal
      :show="modalSearch.show"
      @close="modalSearch.show = false"
      size="md"
      class="text-center"
      danger
    >
      <mdb-modal-body class="px-0 py-0">
        <form @submit.prevent="getSearch">
          <div class="search-icon">
            <input
              v-model="modalSearch.query"
              type="search"
              ref="searchbar"
              name="search"
              placeholder="ex: clients, n° facture, sites, methode de paiements, type de facture ..."
              id="tec-search-input-s1"
              class="tec-search-input color-normal-reversed"
            />
            <i class="fa fa-search fa-md fa-fw" aria-hidden="true"></i>
          </div>
        </form>
      </mdb-modal-body>
    </mdb-modal>
  </div>
</template>

<script>
import themeSwitcher from "../../utils/darklight";
import config from "../../tstba.conf"
import {
  mdbNavbar,
  mdbNavbarBrand,
  mdbNavbarNav,
  mdbIcon,
  mdbDropdown,
  mdbDropdownToggle,
  mdbDropdownMenu,
  mdbDropdownItem,
  mdbModal,
  mdbModalBody,
  mdbNavItem,
  // mdbBtn,
  // mdbBadge
} from "mdbvue";
import { mapActions } from "vuex";
export default {
  name: "NavbarPage",
  components: {
    mdbNavbar,
    mdbDropdown,
    mdbDropdownToggle,
    mdbDropdownMenu,
    mdbDropdownItem,
    // mdbBadge,
    mdbNavbarBrand,
    mdbNavbarNav,
    mdbNavItem,
    mdbIcon,
    mdbModal,
    mdbModalBody,
    // mdbBtn,
  },
  props: {
    page: {
      type: String,
    },
  },
  data() {
    return {
      activePage: this.page,
      toggle: false,

      modalSearch: {
        show: false,
        query: "",
      },
    };
  },
  methods: {
    ...mapActions(["toggleSideNav"]),
    async doSearch() {
      this.modalSearch.query = "";
      this.modalSearch.show = true;
      this.$nextTick(function () {
        this.$refs.searchbar.focus();
      });
    },

    switchTheme(theme) {
      themeSwitcher(theme);
    },
    handleQe(){
      location.href(config.production_tip ? config.tba_sp_prod : config.tba_sp_dev)
    },  
    async getSearch() {
      if (this.modalSearch.query.trim() === undefined || this.modalSearch.query === "") {
        return;
      }

      this.modalSearch.show = false;
      this.$router.replace({
        path: "/factures/recherches",
        query: {
          q: this.modalSearch.query,
        },
      });
    },

    async logout() {
      this.$nprogress.start();
      this.$store
        .dispatch("auth/logout")
        .then(() => {
          this.$nprogress.done();
          this.$router.push({ path: "/login" });
        })
        .catch((error) => {
          this.$nprogress.done();
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async lock() {
      await this.$lock.lock();
    },
  },
  mounted() {},
  computed: {
    computedPage() {
      return {
        activePage: this.page,
      };
    },
  },
};
</script>

<style lang="css">
.navbar .navbar-toggler {
  display: none !important;
  visibility: hidden !important;
}
</style>
<style scoped>
.navbar .nav-item {
  line-height: 24px;
  font-size: 16px;
}

.navbar-collapse {
  order: 0 !important;
}

.navbar .notifications-nav .dropdown-menu {
  width: 23rem;
}

.navbar-expand-lg .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.navbar .dropdown-toggle {
  padding: 0 10px;
  font-size: 16px;
}

.navbar ul {
  padding: 5px 0;
}

.navbar .btn {
  font-weight: 300;
}

.btn-transparent {
  background-color: transparent !important;
  text-transform: none;
  box-shadow: none !important;
}
.btn-transparent:hover,
.btn-transparent:active {
  box-shadow: none !important;
}

.activePage {
  padding-left: 1rem;
  font-size: 1rem;
}

input#tec-search-input-s1 {
  border: none;
  background-color: rgb(255, 255, 255);
  width: 100%;
  padding: 20px 20px 20px 35px;
  box-shadow: 0px 0px 10px 1px rgba(49, 93, 238, 0.493);

  font-size: 1rem;
  color: rgb(28, 29, 31);
}

input#tec-search-input-s1:focus {
  outline: 0;
  background-color: #fff;
}

.search-icon {
  position: relative;
}

.search-icon i {
  color: rgb(49, 93, 238);
  position: absolute;
  left: 5px;
  top: 5px;
  padding: 20px 5px;
}

.item-effect:hover {
  transition: 180ms !important;
  color: rgb(61, 112, 250);
}

@media (max-width: 575.98px) {
  .activePage {
    visibility: hidden;
    padding-left: 0rem;
    font-size: 0.7rem;
  }
  .nav-active .activePage {
    visibility: hidden !important;
    display: none !important;
  }
}
</style>
